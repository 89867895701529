// import "../style/style.css";
import "../sass/main.scss";
import { ajaxAgenda } from "./ajax-agenda";
import ajaxTravaux from "./ajax-travaux";
import initCommon from "./common";
import { Tabs } from "./utils/tabs";
import { tns } from "tiny-slider/src/tiny-slider";

jQuery(function () {
  var sliderBandeau = tns({
    container: ".slider-bandeau",
    items: 1,
    autoplay: false,
    autoplayButtonOutput: false,
    controls: false,
    nav: true,
    controlsContainer: ".controls",
    navContainer: ".nav",
    responsive: {
      748: {
        nav: false,
        controls: true,
      },
    },
  });
  // init common functions for all pages
  const is_archive_event = jQuery(".post-type-archive-event").length > 0;
  const is_archive_actu = jQuery(".post-type-archive-actualites").length > 0;
  const is_travaux = jQuery(".main-travaux").length > 0;
  const is_engagements = jQuery(".main-engagements").length > 0;
  initCommon();
  if (is_archive_actu) {
    jQuery(".select_cat").on("change", function () {
      console.log(jQuery(this).val());
      if (jQuery(this).val() == "-1") {
        jQuery(".archive__item").show();
      } else {
        jQuery(".archive__item").hide();
        jQuery(".archive__item.cat-" + jQuery(this).val()).show();
      }
    });
  }
  if (is_archive_event) {
    ajaxAgenda();
  }
  if (is_travaux) {
    let timer = null;
    jQuery("#search-input").keyup(function (e) {
      clearTimeout(timer);
      timer = setTimeout(ajaxTravaux, 500);
    });
  }

  if (is_engagements) {
    // init Isotope
    jQuery(".engagements__container").isotope({
      itemSelector: ".engagements__item",
      layoutMode: "fitRows",
      fitRows: {
        gutter: 30,
      },
    });
    var $grid = jQuery(".engagements__container").isotope({
      itemSelector: ".engagements__item",
      layoutMode: "fitRows",
    });
    // filter items on button click
    jQuery(".filter-button-group > button").on("click", function () {
      // add active class
      jQuery(".filter-button-group button").removeClass("active");
      jQuery(this).addClass("active");
      var filterValue = jQuery(this).attr("data-filter");
      $grid.isotope({ filter: filterValue });
    });
  }

  var tabs_container = document.getElementById("tabs");
  if (tabs_container !== null) {
    const tabs = new Tabs({
      elem: "tabs",
      open: 0,
    });
  }

  jQuery(".listes__title").on("click", function () {
    jQuery(this).toggleClass("active");
    jQuery(this).next(".listes__content").slideToggle();
  });

  jQuery(".em_map").appendTo(jQuery(".new_map"));
});
