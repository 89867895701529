export default function ajaxTravaux() {
  var searchTerm = jQuery("#search-input").val();
  console.log(searchTerm);
  jQuery.ajax({
    url: "/wp-admin/admin-ajax.php",
    type: "POST",
    data: {
      action: "travaux_search",
      search_term: searchTerm,
    },
    beforeSend: function () {
      jQuery(".travaux-documents").empty();
      jQuery(".loader").show();
    },
    success: function (data) {
      jQuery(".loader").hide();
      jQuery(".travaux-documents").html(data);
      console.log(data);
    },
  });
  return false;
}
